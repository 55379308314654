import React from 'react';
import { Route, Redirect, Link } from 'react-router-dom';
import { message, Breadcrumb, Button, Typography } from 'antd';
import axios from 'axios';
import Auth from 'auth';
import { accessControlListService } from 'services/access_control';

import { HomeOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
const { Title } = Typography;
const _auth = Auth;

export const axiosBase = {
  create() {
    return axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${_auth.getToken()}`,
      },
    });
  },
};

export const accessControls = {
  async fetch() {
    return new Promise((resolve, reject) => {
      var permissions = [];
      const role = 'current';
      accessControlListService(role)
        .then((response) => {
          if (response.data.items && response.data.items.length > 0) {
            const items = response.data.items;
            items.forEach((item) => {
              item.actions.forEach((actionItem) => {
                permissions[item.resource + '.' + actionItem.action] =
                  actionItem.access;
              });
            });
          }
          resolve(permissions);
        })
        .catch((error) => {
          reject(Error('sorry could notload permissions'));
          // dont react
        });
    });
  },

  can(permissions, resource_and_action) {
    if (Array.isArray(resource_and_action)) {
      let isAllowed = false;
      for (var i = 0; i < resource_and_action.length; i++) {
        if (permissions[resource_and_action[i]]) {
          isAllowed = true;
        }
      }
      return isAllowed;
    } else {
      if (permissions && permissions[resource_and_action]) {
        return permissions[resource_and_action];
      } else {
        return false;
      }
    }
  },
};

export function handleApiError(error) {
  if (error) {
    console.log('handleApiError:', error);

    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          message.error('Unauthorzied access!, Please login');
          _auth.signout();
          window.location.href = process.env.REACT_APP_APP_BASE_URL + '/login';
          break;
        default:
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message.error(error.response.data.message);
          } else {
            message.error('Unknown error');
          }

          break;
      } //switch
    }
  } //if error
}

export function handleApiSuccess(success) {
  if (success) {
    if (success.data && success.data.message) {
      message.success(success.data.message);
    }
  }
}

export function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        _auth.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export function MyBreadcrumb(props) {
  const { items } = props;
  return (
    <Breadcrumb style={{ margin: '16px 0' }}>
      <Breadcrumb.Item>
        <Link to="/">
          <HomeOutlined />
        </Link>
      </Breadcrumb.Item>
      {items.map((item, i) => {
        if (item.path) {
          if (item.path === '/back') {
            return (
              <Breadcrumb.Item key={i}>
                <Button type="link" onClick={() => window.history.back()}>
                  {item.label}
                </Button>
              </Breadcrumb.Item>
            );
          } else {
            return (
              <Breadcrumb.Item key={i}>
                <Link to={item.path}>{item.label}</Link>
              </Breadcrumb.Item>
            );
          }
        } else {
          return <Breadcrumb.Item key={i}>{item.label}</Breadcrumb.Item>;
        }
      })}
    </Breadcrumb>
  );
}

export const CountriesJson = [
  {
    name: 'US',
    abbreviation: 'US',
  },
];

export const StatesJson = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Palau',
    abbreviation: 'PW',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];

export const extensions = {
  "": "All",
  "1a9d56d3-9ba3-47d7-91c0-907f652e1c75": "0126",
  "8c777da9-35c3-4eff-a9ed-4e5879654bb4": "0127",
  "e26aeac0-c6e4-4024-aa3b-87c8013ca8f1": "0128",
  "cbe00fec-e023-418e-9234-1bbb7bf4a930": "0129",
  "1f731ecc-041a-4c48-8e85-be6ca2a46d24": "0130",
  "3d7c6950-f1df-4ba8-ac15-33f693ba33b1": "0136",
  "e5267fd7-dac1-41a7-94ee-097ac7d64c11": "0202",
  "0b7e4521-f837-42e3-bc65-28a3d8adb1a1": "0203",
  "410379c1-4af1-4b3e-a6f1-74e3b46bcc1d": "0204",
  "41fbedb8-f435-4b56-903b-12e757e6cb96": "0205",
  "0e9dd5f0-f72a-47c0-8c50-e85222d0e44f": "0217",
  "bfa7b5ec-2cb2-4b91-923e-82cd4856a648": "0333",
  "ea123bc3-ee49-4457-a661-73a65a931583": "0396",
  "0636b0a9-3496-4950-bbbc-b14245372d44": "0439",
  "480b362c-877f-448a-8e9c-089e4a76170c": "0611",
  "0421307d-263f-40e7-a97e-21a235a12362": "0768",
  "44f855c8-b38d-4204-ba81-edccd547d597": "0780",
  "db69d9ab-be82-4203-8877-5a9a4fcadf87": "0801",
  "b9ff8c22-4705-4e2a-b60a-91c88b6aa6db": "0825",
  "202fa291-8ef0-4515-9fb9-a4a89647e8e8": "100",
  "36250ee9-255e-4a51-8254-62f816369dfe": "101",
  "e31cd495-cf94-4abf-b188-8fdfd2dd75ee": "1045",
  "6382fbda-2431-4e93-a41c-297af72d46a8": "1067",
  "c8a0f44b-e10a-455d-9cf6-3ae4af1ada58": "1080",
  "7b0b7902-870a-4b21-a787-bee79575040a": "1207",
  "0ca58782-68c2-454a-b9f4-bdfd9c53fde9": "1229",
  "88327c04-0da1-45d7-b460-51355caf386a": "1230",
  "4b8eb8ec-5227-46c2-b532-c34dd20b8158": "1237",
  "77f3ee7d-49ab-4efb-93e7-5835bd3c776a": "1366",
  "c7ca3b7d-91b1-4051-a817-8b73135a5f69": "1390",
  "949a63d2-f5bf-4910-a12b-5d62d28a94bf": "1421",
  "ef9dce26-9893-466d-a681-e3a102c5ef17": "1440",
  "e6f1881e-9799-44f7-9f86-c911c25dc165": "1446",
  "a2c43067-9e9e-45f2-ad48-e2e56c14fad7": "1478",
  "af1d13b3-3e2f-4e12-8ddd-62819de25443": "1582",
  "7d6703c4-5dbe-4853-ba0b-b3f682bb5378": "1586",
  "85077be3-8589-4a63-8df2-edd7e64b12ae": "1772",
  "feba1c61-d7c4-4e44-950e-41b9ea6778f5": "1801",
  "ec07ae33-31bd-4f14-bddf-56a215f1dc2c": "18201",
  "5477dfbd-98c8-40a1-89d1-10aff6c0ad82": "18202",
  "4b9b6637-2e39-4585-9380-63ac57dbbcf5": "1940",
  "0d867ddc-0735-4a0a-bfc0-980fc69422d3": "2120",
  "4c1f2fed-8273-4ba0-94c3-5f7122baa5c7": "2127",
  "c3266bf0-3db7-4ac6-ae02-82c4e3ce60f3": "2180",
  "9028b838-e659-4404-838e-6b6e9c95386f": "2224",
  "cc055738-194c-4974-a8c0-0d150d02400b": "2266",
  "9a3dda09-60f9-4c74-94e0-e76fa0f2fd02": "23330",
  "274f2213-76a6-4338-82ad-6dfda302b2c1": "2354",
  "477acd0d-6e35-4603-8782-1e723216543f": "2366",
  "6f7c3f56-64f1-4ce8-9a10-7f80ced42f04": "2379",
  "25f39875-8a89-4100-aa1d-a0a8093b27ef": "2414",
  "753d95ff-b080-44b0-b44d-7615f10724ee": "24141",
  "2da0c57c-95b7-488f-b60e-041f1dfd691f": "2598",
  "1a6baf47-69c8-499b-99e6-6131cee866e9": "2609",
  "5bf0548a-d9a2-4e59-bab7-6a3b263e66f0": "2614",
  "be8b5353-d2b4-4d17-b704-51edcfdab829": "2637",
  "d5dabe11-47c7-4ba9-b90f-fa9cda2a39fe": "2638",
  "256d3851-c204-4a4b-a89e-11c7fa504a08": "2640",
  "ddd965ef-4269-434a-87da-e0bcabc44872": "2646",
  "fbfe491a-3b04-4961-b158-a54fe9f6fd46": "2647",
  "a6baca44-f68c-4702-b504-74f57a298aad": "2796",
  "baea39e4-3987-4029-9d31-c7d045300120": "2797",
  "91217410-6477-423f-aab4-1d18fbeb9ee1": "2805",
  "5862bfec-de2a-400e-bbe3-c009a6829843": "28324",
  "c79578db-9f2d-4be1-8377-d1090db7c201": "2926",
  "80277d83-ad8c-4e71-b2ff-a61b56d49967": "2942",
  "87b8d3f6-f389-4308-8a77-c6c6c49fc24b": "2996",
  "9a591cf3-58af-40b1-9857-2b6c18b16ca0": "2997",
  "bd6e5715-dee8-4988-b7a5-88892a0e027a": "3080",
  "566290b5-1bc7-4557-9038-6d0f06f1de40": "3090",
  "1716480d-26e4-428a-a0d3-a998ddea45f1": "3139",
  "ea7b3c4b-8d41-4a10-a204-4cf5b1ea88a9": "3234",
  "034de541-ccf6-4af1-95ab-9bb94b3b29c3": "3330",
  "f97926f9-2a8c-4aaf-bf1a-24a2c7179e60": "3334",
  "e5f868d7-3bae-4854-9e90-6b0871b70d63": "3335",
  "d8dfbeec-2834-4761-946c-cbbc4a2b74be": "3502",
  "0e722d51-e312-4556-9e2f-b3f460e023cb": "3503",
  "fd5e0637-f351-4281-ad7e-7919a1302a10": "3504",
  "994be695-e880-4f4c-8134-903795bbb144": "3505",
  "22c9f5dd-4583-499c-92ec-86009c94a707": "3506",
  "a8cc9176-c81a-4123-89a8-ff086348f6a5": "3522",
  "238f813f-71ff-4c42-ab7b-0f72d81e00fd": "3524",
  "9e0d7955-427f-4c19-8c67-b1ca81a662d7": "3525",
  "e589e5e9-7847-444a-8c45-b3cda421af75": "3621",
  "34b8d7a7-2807-49fd-94e4-ecb57212c731": "3622",
  "2625b9ce-4d8a-4ea5-bb10-e14e80fedd7f": "3623",
  "4fd7abf3-36dc-44a9-a3a6-3e96e4489f0d": "3624",
  "fd9d719e-8002-4bd9-a4c3-5e2a15955100": "3626",
  "99017b11-e5ed-452b-bcfa-5339b202e127": "3631",
  "329729ee-ffde-422d-9a6b-971dfa209279": "3632",
  "8cc58c04-955b-4040-8cf4-5ee8e6cbab39": "3633",
  "3224bc73-45a4-4b45-84ef-e9d2e00800d4": "3643",
  "87a68478-cb73-42ad-8d81-4b790eac3f16": "3686",
  "7c2c53b6-17ea-4f47-9fdc-a32264599375": "3689",
  "580d5b19-31b1-4d11-9d16-016020dabcbb": "3690",
  "21117778-cda1-4b6f-9718-c4f0f9e23827": "3691",
  "51001030-351f-469f-8b3a-92593326dd8b": "3692",
  "2f3afff8-9efc-4490-81e6-0f7d21c39c64": "3693",
  "65de2a5c-6ae5-441a-bf5c-dbd8cd6e2d4c": "3694",
  "b1904eca-911c-43dd-b076-01246f0b8264": "3900",
  "72d4b077-3b73-4e2c-aa41-c43e3e01cc6c": "3972",
  "02dff515-dd0d-4bca-8834-58f2d2119543": "39841",
  "6d65a645-cecc-4e9c-a0b6-74b6f367364a": "4083",
  "ed432e9f-ecf6-4b2c-982e-b8fa27eef470": "4088",
  "7be4188b-42f9-4df5-b5f9-5d98b3c0a6f5": "4111",
  "29d0d139-46d6-4378-92f8-a3fe71652f28": "4112",
  "bdcf88f7-5eb7-45d0-bab5-450d8b388463": "4113",
  "b001702d-b705-4613-8da5-ffa0b9798543": "4123",
  "06972758-b171-43ef-a4de-28d627bc3e79": "4250",
  "00ef4b16-bc61-428d-ac1a-ad2c281b80a2": "4303",
  "1340b590-d6b6-4716-b857-9caa3b5e80ba": "4313",
  "497e9b2b-f390-4bf0-b40f-b031df97314d": "4383",
  "265c8d0a-2954-4cf5-a951-ad4c621b8efe": "4429",
  "a3381ac9-64c8-4da0-ba59-56c73381a5df": "4601",
  "1f9eca9c-e70d-4c90-83c0-181a6399ba6e": "4603",
  "fc832d3a-6735-413a-ab55-df926fdc7fd4": "4625",
  "6aa60b1c-b624-4faf-b888-f6b5536c75c2": "4626",
  "de832e4d-67d8-454e-98b2-92c5246a805e": "4720",
  "c3c84592-a4ec-4691-8b0b-a1de9d449c06": "4840",
  "5248e4e0-2975-4a77-99bb-06f023fb4e64": "4841",
  "3da9a47d-c1df-4a37-b0d3-3dfd5141fa60": "4842",
  "3f6f8671-c5bd-4780-bdbe-f3b5b513abe1": "4843",
  "644a4cf2-5460-49f4-ad7a-020e772d0289": "4844",
  "817667a7-5acb-41b9-b460-583f4a12709f": "4845",
  "fc5d10f3-cca1-4bdc-ac71-919248d37942": "4846",
  "becd5d53-f27f-4a4a-9c3a-b292f4b7164d": "4847",
  "1abaa849-0ae3-4b9e-a35c-4b0286a6928a": "4865",
  "b38af718-20a6-4239-bca7-02754240b44d": "4948",
  "3f97a6e9-50e2-49b0-9015-11efbc847f1f": "4987",
  "2768b1a3-4578-4be5-bb67-15a3d35ba966": "4988",
  "0e75de32-e8fc-47cc-8ea1-9043098f588e": "4989",
  "76792be1-63da-4b75-8ed6-1c796f6b635d": "4990",
  "c0d11bfb-a3f6-41e3-b8d1-5e9ce93442b9": "4991",
  "977d6b9a-73fa-42a7-9a5d-7426a9190f8a": "4992",
  "d3f872c9-69d7-4f64-8087-da3d1a8452e0": "4993",
  "4db100d6-e622-4b82-a03a-a558548b085f": "4994",
  "22f614c2-2b8f-4eb8-95e6-8dd926abaf7a": "4995",
  "d8045620-1e6b-457e-8c81-efd04dc7a378": "4996",
  "a75b99cc-900c-4c2f-8c88-e4f6f422f1b5": "4997",
  "b52c1714-0526-4a1b-9078-0f795430b841": "5000",
  "517359c3-3f66-474c-b577-5460476acdc2": "5111",
  "70571e02-122e-44e4-8f94-160d98d13cf3": "5114",
  "9aef866b-7e09-4f89-9c98-8edffa4328ac": "5120",
  "6341047d-5950-4757-b634-1c6925d496fb": "5123",
  "52de9a42-17a4-4fd8-8a6b-285dfd3bb3be": "51231",
  "82c62e62-2459-449b-bae1-f7e4eb063ba2": "51232",
  "e5bcdeb0-5d90-46f5-ac14-7291d985cbe8": "51233",
  "97e6410f-8e6c-41db-829c-f5016919b9f7": "51234",
  "ed98af25-fa54-4f2e-b99b-afed722514ef": "51235",
  "618053ee-447c-470d-8706-48fa7e095442": "51236",
  "a3b33aab-7fe2-4128-8cb5-911d0750349e": "5131",
  "0c33cdfa-8cb7-4cc7-bcc2-35412482279c": "5148",
  "974f0157-86bc-4041-9b8f-69ff3cf6fb90": "5150",
  "9b705abc-78d9-40e9-9ac3-bcf3b60aac48": "51580",
  "bcf5c8e3-094c-48c6-8ef8-2a3b71aa5d7e": "5163",
  "f4053c5e-61dd-46c2-8329-db40550193a9": "5165",
  "ae66aeb7-0bab-41c0-a429-cf75a340efbc": "5178",
  "744ef48b-b886-4787-9f0e-cb4457f1b8db": "5188",
  "97171061-73fc-47e4-8a83-d819efc4bf36": "5190",
  "f1f3200c-57b9-435c-87a1-58a11dac89ae": "5192",
  "f74e7e3f-e38e-4bdf-9f27-9f86037e66fb": "5214",
  "82f0694f-5c99-44ec-987c-dfabc0e6069a": "5229",
  "109507c0-d3ae-43ed-9f3b-3edb2a3c2ef4": "5291",
  "d9265729-d7d7-412a-9cd9-39b5b82f0fd8": "5292",
  "6c661786-2f72-4205-8b5b-93099b262e9f": "5380",
  "88d13085-e5d9-4750-9ef2-400c1abf7edb": "5383",
  "045c6c66-0a4c-4028-b9ea-0911383cd2bc": "5384",
  "f35975d2-12c5-40df-b579-dde27e1f7fc0": "5385",
  "f3c77254-aa06-4d33-a4f0-685b998c3e76": "5480",
  "3cbd771c-2839-48ec-a259-eb80ff1549dc": "5481",
  "2c884d55-518b-4fa3-a6f4-fd4519d84a6d": "5521",
  "dd8a007b-3d55-478e-b6ae-ea1592836ad7": "5522",
  "f1af1916-98aa-469f-b882-fd5fb74a8bee": "55899",
  "6932ac29-4a25-4428-8f3c-e93b221fd009": "5770",
  "9e027cb3-61ef-4201-a349-b40cc802f203": "5841",
  "155304d7-3449-4d39-b6d9-3a893097487f": "5865",
  "a14e5153-2327-4e44-8220-3a8226edf20d": "6000",
  "2998b5e8-2af7-49e8-9c22-6531232712bf": "6002",
  "415dd8bb-a7de-4a31-bb4a-d741d82dcf11": "6018",
  "25ca6cc3-9644-4b54-b11f-951527853d36": "6082",
  "11fa30d8-f1c9-4725-89ad-f72b5877a34d": "6084",
  "451e8f2a-0dad-40d8-98a9-a7eb94c32e04": "6245",
  "8ec3a8b5-9ab5-4a18-abef-990ca2f08c8d": "63625",
  "297aed88-ef39-4777-9ccf-957123dea0f8": "6902",
  "86c1e241-bdaa-4061-ba29-f0e8c0752181": "6962",
  "b8119437-86d8-48e2-80df-b60668fa44fc": "7053",
  "bd3f16c7-0dc5-409a-b947-9c90892f0008": "7062",
  "d17238a0-9961-42a9-9aa6-060e73e7198b": "7108",
  "0d93b72a-4684-4d47-af2a-e652a5cb75b1": "7151",
  "0840defa-b11d-4aec-b5f3-64fa29729e2b": "7152",
  "1f186e21-91bb-426c-bb2b-024a2bc8e0d9": "72637",
  "05333b44-620a-413d-b865-c69cc4996f9f": "7303",
  "433071c6-ba36-4984-8abe-e328373703d9": "7361",
  "ec380d8c-574c-4ccd-98e4-8b857d871838": "7369",
  "9018f084-2351-4a24-b040-174c9eafaaca": "7405",
  "01a59dd0-c637-4164-8975-e1ab56691841": "7406",
  "1273afd7-5d3a-485d-9fa1-f462109cd895": "7408",
  "34bbede8-f00b-41ae-bffe-9c132af96a9e": "7409",
  "cc978068-ddcd-4594-8672-06108817c291": "7410",
  "390acb71-5197-4ba2-ab79-e4223b467736": "7416",
  "d1ca98a4-278d-4cc9-981e-90e9aa1d0c42": "7419",
  "8b6e3622-624d-4d9f-b5ec-cd55c7ed73cd": "7510",
  "8df48e0b-10af-42de-8bc0-e7c07f309563": "7599",
  "b394ac00-39d1-4d87-9777-28bafc1b0d2d": "7689",
  "4430a806-c8f4-4bd0-a76e-358b13cfb278": "7792",
  "4f7b41aa-2145-4e07-8c52-49e34fff517e": "7793",
  "1966c33e-e39b-4553-989f-9631026c3c43": "7794",
  "2bf3855d-bf92-40fa-9a95-46bb7a25410b": "7926",
  "3a69c6a8-0aaa-4186-a768-df17b2da6076": "7928",
  "8a4ad29f-1632-43d6-b134-b867820c7186": "7929",
  "a3ec7a29-5b75-4c7a-b89b-7dcaf5e99996": "8065",
  "afe57fa3-e3ec-4709-83b7-100bbe8679fe": "8147",
  "49ac96ef-bb6a-428a-bbc2-a882ad476e72": "8148",
  "80a148f3-da77-462e-b603-dc25b31a27bf": "8282",
  "adae2804-7141-4838-a502-6350b3c8968d": "8300",
  "264c3640-db61-437a-9e23-0dde00c3ff96": "8324",
  "6ea0e34a-6dbf-486e-aa76-e9c45b1ee7da": "8325",
  "4548cb09-1a8b-4547-888a-6916ee2206f6": "8339",
  "b7534ede-cf55-4089-8516-24d1bcc309d9": "8342",
  "0df53cc3-7ce0-42b6-97b0-eec5eede5ffd": "8347",
  "159234eb-e8ea-4d6d-8289-e52bc3f2a5c9": "83562",
  "823f57af-93ff-4d9e-8bb9-63d744c7c5c0": "8360",
  "7c7d0422-ee29-4d0c-8276-267b87ac344a": "8380",
  "aa3c7c7f-3ae6-4447-9dc2-3d228e3afa30": "83811",
  "6df7e116-44b1-4949-80b0-c5b56dba4ee8": "83812",
  "fc3d1a41-2dfc-4216-ad87-4ecd538f816f": "83813",
  "bf08ce3b-1208-4a9c-b8f3-e5a518257469": "83814",
  "9314de74-9761-4d5e-8d69-49efa38e8cb7": "83815",
  "cd2adcab-626b-4056-b011-89d3f2d3f398": "8389",
  "096194a9-6832-435a-8644-0cb5604f2d16": "8394",
  "de3caea0-3291-4c73-a325-81e1ae8fa5bd": "8399",
  "50301d18-f207-42d7-9027-deaa4b094ca1": "8402",
  "ea690088-bacb-4402-bece-8a255d741985": "8403",
  "d229aa64-3c6f-451f-9fcd-5a254a89283b": "8404",
  "0feafb49-2790-4e2b-ad51-5bf45f8300a3": "8590",
  "69b8fafd-084d-4de1-8e9f-eb0d03a26d7f": "8591",
  "86a27382-def6-48cc-a965-87fd0b50da08": "8592",
  "c31e67e9-30b2-45af-aee7-e63ff9587313": "8593",
  "d700a9a8-6ee1-436f-8367-59edcae69a30": "8594",
  "a262495b-640c-47e7-a31d-0a395df10a17": "8595",
  "fc1636df-3da6-4522-9bbd-69ef069de66b": "8680",
  "82d65def-51f2-4ee6-b666-fa5f385bdc1d": "8740",
  "221b4313-deab-4aa6-a3c1-f3db4d3ee1d5": "8804",
  "2db817b0-234c-4a12-8a6f-10816d254f2d": "8806",
  "9aa9b812-d98a-4517-9359-bbec20fb5cc8": "8890",
  "6a2ad114-32f6-4138-a5d1-526f8afde854": "8891",
  "8fc63f79-b64d-4113-aa4d-ef2acacde65b": "8943",
  "62ce5c85-a3d4-4f88-a959-113b3b235389": "8981",
  "63cdc879-329a-4fee-8ec6-f9eddfc07b2b": "8986",
  "878c91e7-2baa-47d4-8b87-28a2c8fc06a3": "9022",
  "5c1b547b-ddb9-4d25-85f0-379ea0ebc22e": "9041",
  "a51fa423-820a-4b55-bea7-24816dff2f10": "9179",
  "f0e86238-7621-4ccf-81ea-7d742a0a80b5": "9221",
  "6fb328f4-ad8a-4745-a3f9-c6520f601786": "9223",
  "3193cc12-5fc7-4ae8-be7d-d4c858362f22": "9224",
  "b7d7416d-3244-4150-9dfc-f70caafed0de": "9281",
  "da5c93ca-22d5-47a2-b3a4-9754102d2b25": "9297",
  "f2eb32e2-0dbf-4f7a-ace7-4629bb037a15": "9324",
  "98fd9b5e-2862-4454-8e03-7a0fb6f79024": "9325",
  "593176de-66fa-478c-a6c0-7fd35da65b3b": "9502",
  "744f83e4-a444-4bce-9dcf-acf751cfe898": "9503",
  "3665757a-a836-4127-b368-a743ad4f0a35": "9504",
  "359a52ca-04f5-46a1-9485-0a2948961748": "9555",
  "1c03c5eb-371c-41e4-a634-0f0df4b9cd19": "9601",
  "7c03d236-1724-4118-aab1-ccec3737cd6a": "9668",
  "6bab1162-d3ed-4a51-a385-78b88562186b": "9810",
  "8f253789-8f48-4f32-b5eb-18e2e78386e8": "9812",
  "4305052c-d8a9-43ed-8ad0-3c82e970fd66": "9816",
  "f7f145a4-bc21-4eee-a60d-a9712af9608f": "98326",
  "6ec4b02f-540b-490a-9fd4-3403b62f9f67": "99",
  "d65df8d6-755b-4263-b17d-3e693455e069": "9998",
  "e5667b1c-c3a1-4bd9-aab0-2b17a08e42b7": "9999"
}


export const msptrwStorage = {
  set(key, val) {
    localStorage.setItem(key, val);
  },
  get(key) {
    return new Promise((resolved, reject) => {
      let storedVal = localStorage.getItem(key);
      if (storedVal) {
        resolved(storedVal);
      } else {
        reject(null);
      }
    });
  },
};

export function confirmDelete(text) {
  return new Promise((resolved, reject) => {
    Swal.fire({
      title: 'Confirm!',
      text: text ? text : 'Are you sure you want to delete!',
      icon: 'warning',
      confirmButtonText: 'Ok',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        resolved(true);
      } else {
        reject(true);
      }
    });
  });
}

export function countryFullName(str) {
  switch (str) {
    case 'US':
      return 'United States of America';
    default:
      return str;
  }
}

export function IPtoNum(ip) {
  return Number(
    ip
      .split('.')
      .map((d) => ('000' + d).substr(-3))
      .join(''),
  );
}
export function checkIpValue(value) {
  const subips = value.split('.');
  if (subips.length > 4) {
    return false;
  }
  const invalidSubips = subips.filter((ip) => {
    ip = parseInt(ip);
    return ip < 0 || ip > 255;
  });
  if (invalidSubips.length !== 0) {
    return false;
  }
  let emptyIpCount = 0;
  subips.forEach((ip) => {
    if (ip === '') {
      emptyIpCount++;
    }
  });
  if (emptyIpCount > 1) {
    return false;
  }
  return true;
}

export function getServiceAreaName(type) {
  switch (type) {
    case 'NATION_WIDE':
      return 'Nation Wide';
    case 'SELECT_STATE':
      return 'Select a State';
    default:
      return 'N/A';
  }
}

export function calculateSubnetAndOthers(subnet, vlan) {
  if (!subnet) {
    return null;
  }
  const myArray = subnet.toString().split('.');
  if (!myArray[0] && !myArray[1]) {
    return null;
  }
  let payload = {};
  payload.subnet = `${myArray[0]}.${myArray[1]}.${vlan}.0`;
  payload.gateway = `${myArray[0]}.${myArray[1]}.${vlan}.1`;
  payload.dhcp_start = `${myArray[0]}.${myArray[1]}.${vlan}.51`;
  payload.dhcp_end = `${myArray[0]}.${myArray[1]}.${vlan}.254`;
  payload.staticIp_start = `${myArray[0]}.${myArray[1]}.${vlan}.2`;
  payload.staticIp_end = `${myArray[0]}.${myArray[1]}.${vlan}.50`;
  return payload;
}

export function niceName(str) {
  let i,
    frags = str.split('_');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    if (frags[i] === 'Id') {
      frags[i] = 'ID';
    }
  }
  return frags.join(' ');
}

export function renderInfoBlock(payload) {
  let valueCount = 0;
  const itemsHtml = payload.items.map((item, index) => {
    let value = payload.source[item] || null;
    if (value && value !== '') {
      valueCount++;
    }
    if (typeof item === 'string' && value && value !== '') {
      return (
        <div>
          <strong>{niceName(item)}:</strong> {value}
        </div>
      );
    } else if (typeof item === 'object') {
      return (
        <div>
          <strong>{niceName(item.key)}:</strong> {item.value}
        </div>
      );
    } else {
      return null;
    }
  });
  /*
  {
    heading: "",
    source : {},
    items: [{key:"",value:""},"string"]
  }
  */
  if (valueCount > 0) {
    return (
      <div style={{ border: '1px solid #DDD', padding: 10, height: '100%' }}>
        <Title level={3}>{payload.heading}</Title>
        {itemsHtml}
      </div>
    );
  } else {
    return null;
  }
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    'Bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
