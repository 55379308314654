import React, { useEffect, useRef } from "react";
import { Layout, Input, Avatar, Typography, Space, Button, Spin } from "antd";
import { UserOutlined, RobotOutlined, BulbOutlined, ArrowUpOutlined, SendOutlined } from "@ant-design/icons";
import { queryKnowledgeBaseBotService } from "services/knowledgebot";
import Markdown from 'markdown-to-jsx';

const { Content } = Layout;
const { Text } = Typography;


const MarkdownToJSX = ({ markdownContent }) => {
    return (
        <Markdown>
            {markdownContent}
        </Markdown>
    );
};


const KnowledgeBot = () => {
    const chatContainer = useRef(null);
    const [isBotTyping, setIsBotTyping] = React.useState(false);
    const [prompt, setPrompt] = React.useState("");
    const [messages, setMessages] = React.useState([]);

    const timeAgo = (timestamp) => {
        const now = new Date();
        const messageDate = new Date(timestamp);
        const seconds = Math.floor((now - messageDate) / 1000);
        const intervals = {
            year: 31536000,
            month: 2592000,
            day: 86400,
            hour: 3600,
            minute: 60,
            second: 1,
        };

        for (const [unit, value] of Object.entries(intervals)) {
            const interval = Math.floor(seconds / value);
            if (interval >= 1) {
                return `${interval} ${unit}${interval > 1 ? 's' : ''} ago`;
            }
        }
        return "just now";
    };

    const handleSubmit = () => {
        if (prompt.trim() === "") return;
        setIsBotTyping(true);
        const newUserMessage = {
            sender: "You",
            text: prompt,
            isBot: false,
            timestamp: new Date(),
        };
        setMessages((prevMessages) => [...prevMessages, newUserMessage]);
        queryKnowledgeBaseBotService(prompt)
            .then((response) => {
                setIsBotTyping(false);
                const newBotMessage = {
                    sender: "MSPTRW Bot",
                    text: response.data.response,
                    isBot: true,
                };
                setMessages((prevMessages) => [...prevMessages, newBotMessage]);
                localStorage.setItem("prompts", JSON.stringify([...messages, newUserMessage, newBotMessage]));
            })
            .catch((error) => {
                setIsBotTyping(false);
                const newBotMessage = {
                    sender: "MSPTRW Bot",
                    text: "Sorry, I am unable to process your request at the moment. Please try again later.",
                    isBot: true,
                };
                setMessages((prevMessages) => [...prevMessages, newBotMessage]);
                console.error(error);
            });
        setPrompt("");
    };

    useEffect(() => {
        chatContainer.current.scrollTop = chatContainer.current.scrollHeight + 200;
    }, [messages]);

    useEffect(() => {
        const storedPrompts = JSON.parse(localStorage.getItem("prompts"));
        if (storedPrompts) {
            setMessages(storedPrompts);
        }
    }, []);

    return (
        <div style={{
            background: "#fff",
            height: "100vh",
        }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                }}>
                <div ref={chatContainer} style={{
                    height: "100%",
                    overflowY: "auto",
                    scrollbarWidth: "none",
                }}>
                    {
                        messages.length > 0 ? messages.map((message, index) => (
                            message.isBot ? (
                                <div key={index} style={{
                                    display: "flex",
                                    gap: "10px",
                                    padding: "20px 50px",
                                    backgroundColor: "#ffffff",
                                    width: "100%",
                                }}>
                                    <Avatar
                                        style={{
                                            backgroundColor: "#2a2a2",
                                            color: "black",
                                            verticalAlign: "middle",
                                        }}
                                        size="large"
                                        icon={<BulbOutlined />}
                                    />
                                    <div style={{ flex: 1, overflowY: "auto" }}>
                                        <Text
                                            style={{
                                                display: "block",
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                                marginBottom: "5px",
                                                color: "black",
                                            }}
                                        >
                                            {message.sender}
                                        </Text>
                                        <MarkdownToJSX markdownContent={message.text} />
                                    </div>
                                </div>
                            ) : (
                                <div key={index} style={{
                                    display: "flex",
                                    gap: "10px",
                                    padding: "20px 50px",
                                    backgroundColor: "rgba(240, 240, 240, 0.8)",
                                    width: "100%"
                                }}>
                                    <Avatar
                                        style={{
                                            backgroundColor: "#222",
                                            color: "white",
                                            verticalAlign: "middle",
                                        }}
                                        size="large"
                                        icon={<UserOutlined />}
                                    />
                                    <div style={{ flex: 1, overflowY: "auto" }}>
                                        <Text
                                            style={{
                                                display: "block",
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                                marginBottom: "5px",
                                                color: "black",
                                            }}
                                        >
                                            {message.sender}
                                        </Text>
                                        <Text
                                            style={{ display: "block", fontSize: '16px', marginBottom: "5px", color: "black" }}
                                        >
                                            {message.text}
                                        </Text>
                                        <Text
                                            type="secondary"
                                            style={{ display: "block", marginBottom: "10px", color: "gray" }}
                                        >
                                            {timeAgo(message.timestamp)}
                                        </Text>
                                    </div>
                                </div>
                            )
                        )) : (
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                color: "white",
                            }}>
                                <Text
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "bold",
                                        color: "black",
                                        textAlign: "center",
                                        marginLeft: 100
                                    }}
                                >
                                    Start a conversation with MSPTRW Bot
                                </Text>
                            </div>
                        )
                    }
                </div>
                <Spin
                    style={{
                        padding: "20px",
                    }}
                    spinning={isBotTyping}
                    tip="Insta Bio bot is typing..."
                />
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                    style={{
                        position: "fixed",
                        bottom: 0,
                        width: "100%",
                        background: "#fff",
                        padding: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Input
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        placeholder="Type your message..."
                        className="custom-input"
                        style={{
                            maxWidth: "50%",
                            marginRight: "120px",
                            borderRadius: "20px",
                            padding: "20px 15px",
                            background: "rgba(220, 220, 220, 0.8)",
                            color: "black",
                            border: "none",
                        }}
                        suffix={
                            <ArrowUpOutlined
                                style={{
                                    fontSize: "18px",
                                    color: "black",
                                    cursor: "pointer",
                                }}
                                onClick={handleSubmit}
                            />
                        }
                        autoSize={{ minRows: 1, maxRows: 3 }}
                        disabled={isBotTyping}
                    />
                    <style>
                        {`
                            .custom-input input {
                                background: transparent !important;
                                color: #222;
                                border: none;
                            }

                            .custom-input .ant-input-suffix {
                                background: transparent; /* Remove white suffix background */
                            }
                        `}
                    </style>
                </form>
            </div>
        </div>
    );
};

export default KnowledgeBot;
