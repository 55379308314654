import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ControlPanelLayout from 'layouts/ControlPanelLayout';
import { Result, Spin } from 'antd';
import { Fade } from 'react-awesome-reveal';

// Pages
import Dashboard from 'pages/dashboard';
import Clock from 'pages/clock';
import Login from 'pages/login';
import Logout from 'pages/user/logout';
import AccessControl from 'pages/access_control';

// USER ROUTES
import UsersList from 'pages/user';
import UsersCreate from 'pages/user/create';
import UsersUpdate from 'pages/user/update';
import UsersUpdatePassword from 'pages/user/update-password';
import UsersPrint from 'pages/user/print';
import UsersView from 'pages/user/view';

// THIRD-PARTY CREDENTIALS ROUTES
import ThirdPartyCredentialsList from 'pages/third_party_credentials/index';
import ThirdPartyCredentialsCreate from 'pages/third_party_credentials/create';
import ThirdPartyCredentialsUpdate from 'pages/third_party_credentials/update';

// CALL REPORT

import CallReport from 'pages/call-report';

// CLIENT ROUTES
import ClientsList from 'pages/client';
import ClientsCreate from 'pages/client/create';
import ClientsUpdate from 'pages/client/update';

// COMPANY ROUTES
import CompaniesList from 'pages/company';
import CompaniesCreate from 'pages/company/create';
import CompaniesUpdate from 'pages/company/update';
import CompaniesView from 'pages/company/view';
import CompaniesRole from 'pages/company/role';

// BRANCH ROUTES
import BranchesList from 'pages/branch';
import BranchesCreate from 'pages/branch/create';
import BranchesUpdate from 'pages/branch/update';
import BranchesView from 'pages/branch/view';

// WORKSPACE ROUTES
import WorkspaceList from 'pages/workspace';
import WorkspaceCreate from 'pages/workspace/create.';
import WorkspaceUpload from 'pages/workspace_uploads';
import WorkspaceUpdate from 'pages/workspace/update';
import WorkspaceUploadCreate from 'pages/workspace_uploads/create';
import WorkspaceUploadUpdate from 'pages/workspace_uploads/update';
import WorkspacePropertyList from 'pages/workspace_property';
import WorkspacePropertyCreate from 'pages/workspace_property/create';
import WorkspacePropertyUpdate from 'pages/workspace_property/update';
import WorkspaceQuotes from 'pages/workspace_quote';

//SALES PERSON ROUTES

import Salesperson from 'pages/sales_person';
import SalespersonCreate from 'pages/sales_person/create';
import SalespersonUpdate from 'pages/sales_person/update';

// CATEGORY ROUTES
import CategoriesList from 'pages/category';
import CategoriesCreate from 'pages/category/create';
import CategoriesUpdate from 'pages/category/update';
import CategorieShare from 'pages/category/share';

//KNOWLEDGE BASE ROUTES
import KnowledgeBaseViewPostDetail from 'pages/knowledge_base/knowledge_base_view_post_detail';
import KnowledgeBaseViewPostList from 'pages/knowledge_base/knowledge_base_view_post_list';
import KnowledgeBaseView from 'pages/knowledge_base/view';
import KnowledgeBaseManage from 'pages/knowledge_base/manage';
import KnowledgeBaseList from 'pages/knowledge_base';
import KnowledgeBaseCreate from 'pages/knowledge_base/create';
import KnowledgeBaseUpdate from 'pages/knowledge_base/update';
import KnowledgeBaseWebsite from 'pages/website/menu';

// KNOWLEDGE BOT ROUTES

import KnowledgeBot from 'pages/knowledgebot';

// GALLERY ROUTES
import GalleryList from 'pages/gallery';
import GalleryCreate from 'pages/gallery/create';
import GalleryUpdate from 'pages/gallery/update';
import GalleryView from 'pages/gallery/view';

// ISP PROVIDER ROUTES
import IspProviderList from 'pages/isp_provider';
import IspProviderCreate from 'pages/isp_provider/create';
import IspProviderUpdate from 'pages/isp_provider/update';

// ISP ROUTES
import IspList from 'pages/isp';
import IspCreate from 'pages/isp/create';
import IspUpdate from 'pages/isp/update';
import IspView from 'pages/isp/view';

// Utility ROUTES
import UtilityProvidersList from 'pages/utility_providers';
import UtilityProvidersCreate from 'pages/utility_providers/create';
import UtilityProvidersUpdate from 'pages/utility_providers/update';
import UtilityProvidersView from 'pages/utility_providers/view';

// Service ROUTES
import ServiceProvidersList from 'pages/service_providers';
import ServiceProvidersCreate from 'pages/service_providers/create';
import ServiceProvidersUpdate from 'pages/service_providers/update';
import ServiceProvidersView from 'pages/service_providers/view';

// ADMINCONTROL ROUTES
import AdminControl from 'pages/admincontrol';
import ExpectScripts from 'pages/expect-scripts';
import RaddbReports from 'pages/raddb-reports';

import { PrivateRoute } from 'utils';

// FETCH ROUTE INFO FROM FILES AND GENERATE ROUTE ARRAY
const dynamicImports = [];
dynamicImports.push(import('./pages/network-management/routes'));
dynamicImports.push(import('./pages/gallery/routes'));
dynamicImports.push(import('./pages/workspace/routes'));
dynamicImports.push(import('./pages/sales_person/routes'));
dynamicImports.push(import('./pages/billing/routes'));
dynamicImports.push(import('./pages/website/routes'));
let promises = [];
for (let i = 0; i < dynamicImports.length; i++) {
  promises.push(dynamicImports[i]);
}

function App() {
  const [dynamicRoutes, setDynamicRoutes] = useState([]);
  const [pageComponents, setPageComponents] = useState([]);
  useEffect(() => {
    Promise.all(promises).then((resolvedItems) => {
      let items = [];
      let _pageComponents = [];
      for (let i = 0; i < resolvedItems.length; i++) {
        const mainMenu = resolvedItems[i].default;
        items.push(mainMenu);
        if (mainMenu.component) {
          const path = `./pages${mainMenu.component}`;
          _pageComponents[mainMenu.key] = React.lazy(() => import(`${path}`));
        }
        if (mainMenu.items && mainMenu.items.length > 0) {
          const subMenu = mainMenu.items;
          for (let j = 0; j < subMenu.length; j++) {
            if (subMenu[j].component) {
              const path = `./pages${subMenu[j].component}`;
              _pageComponents[subMenu[j].key] = React.lazy(() =>
                import(`${path}`),
              );
            }
          }
        }
      }
      setPageComponents(_pageComponents);
      setTimeout(() => {
        setDynamicRoutes(items);
      }, 500);
    });
  }, []);

  const PublicPages = () => {
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
      </Switch>
    );
  };

  const PrivatePages = () => {
    return (
      <ControlPanelLayout dynamicRoutes={dynamicRoutes}>
        <Suspense
          fallback={
            <div>
              <Spin />
            </div>
          }
        >
          <Fade>
            <Switch>
              <PrivateRoute exact path="/call-report" component={CallReport} />
              <PrivateRoute
                exact
                path="/clients/create"
                component={ClientsCreate}
              />
              <PrivateRoute
                exact
                path="/clients/update/:id"
                component={ClientsUpdate}
              />
              <PrivateRoute exact path="/clients" component={ClientsList} />

              <PrivateRoute
                exact
                path="/companies/create"
                component={CompaniesCreate}
              />
              <PrivateRoute
                exact
                path="/companies/update/:id"
                component={CompaniesUpdate}
              />
              <PrivateRoute exact path="/companies" component={CompaniesList} />
              <PrivateRoute
                exact
                path="/companies/view/:id"
                component={CompaniesView}
              />

              <PrivateRoute
                exact
                path="/companies/role/:id"
                component={CompaniesRole}
              />

              <PrivateRoute
                exact
                path="/branches/create"
                component={BranchesCreate}
              />
              <PrivateRoute
                exact
                path="/branches/update/:id"
                component={BranchesUpdate}
              />
              <PrivateRoute exact path="/branches" component={BranchesList} />

              <PrivateRoute
                exact
                path="/branch/view/:id"
                component={BranchesView}
              />

              <PrivateRoute exact path="/workspace" component={WorkspaceList} />

              <PrivateRoute
                exact
                path="/workspace/create"
                component={WorkspaceCreate}
              />

              <PrivateRoute
                exact
                path="/workspace/update/:id"
                component={WorkspaceUpdate}
              />

              <PrivateRoute
                exact
                path="/workspace/:workspaceLocationId/:workspaceSlug/property"
                component={WorkspacePropertyList}
              />

              <PrivateRoute
                exact
                path="/workspace/:workspaceLocationId/:workspaceSlug/property/create"
                component={WorkspacePropertyCreate}
              />

              <PrivateRoute
                exact
                path="/workspace/:workspaceLocationId/:workspaceSlug/property/update/:id"
                component={WorkspacePropertyUpdate}
              />

              <PrivateRoute
                exact
                path="/workspace/:objectId/:objectType/upload"
                component={WorkspaceUpload}
              />

              <PrivateRoute
                exact
                path="/workspace/:objectId/:objectType/upload/create"
                component={WorkspaceUploadCreate}
              />

              <PrivateRoute
                exact
                path="/workspace/:objectId/:objectType/upload/update/:id"
                component={WorkspaceUploadUpdate}
              />

              <PrivateRoute
                exact
                path="/workspace/:workspaceLocationId/property/quotes/:id"
                component={WorkspaceQuotes}
              />

              <PrivateRoute exact path="/sales" component={Salesperson} />

              <PrivateRoute
                exact
                path="/sales/create"
                component={SalespersonCreate}
              />

              <PrivateRoute
                exact
                path="/sales/update/:id"
                component={SalespersonUpdate}
              />

              <PrivateRoute
                exact
                path="/users/create"
                component={UsersCreate}
              />
              <PrivateRoute
                exact
                path="/users/update/:id"
                component={UsersUpdate}
              />
              <PrivateRoute
                exact
                path="/users/update-password/:id"
                component={UsersUpdatePassword}
              />
              <PrivateRoute exact path="/users" component={UsersList} />

              <PrivateRoute exact path="/users/print" component={UsersPrint} />
              <PrivateRoute
                exact
                path="/users/view/:id"
                component={UsersView}
              />

              <PrivateRoute
                exact
                path="/third-party-credentials/create"
                component={ThirdPartyCredentialsCreate}
              />
              <PrivateRoute
                exact
                path="/third-party-credentials/update/:id"
                component={ThirdPartyCredentialsUpdate}
              />
              <PrivateRoute
                exact
                path="/third-party-credentials"
                component={ThirdPartyCredentialsList}
              />

              <PrivateRoute
                exact
                path="/:type/categories/create"
                component={CategoriesCreate}
              />
              <PrivateRoute
                exact
                path="/:type/categories/update/:id"
                component={CategoriesUpdate}
              />
              <PrivateRoute
                exact
                path="/:type/categories/share/:id"
                component={CategorieShare}
              />
              <PrivateRoute
                exact
                path="/:type/categories"
                component={CategoriesList}
              />
              <PrivateRoute
                exact
                path="/:type/chat"
                component={KnowledgeBot}
              />

              <PrivateRoute
                exact
                path="/knowledge-base/manage/category-:id"
                component={KnowledgeBaseManage}
              />

              <PrivateRoute
                exact
                path="/knowledge-base/view/post-:id"
                component={KnowledgeBaseViewPostDetail}
              />
              <PrivateRoute
                exact
                path="/knowledge-base/view/category-:id"
                component={KnowledgeBaseViewPostList}
              />
              <PrivateRoute
                exact
                path="/knowledge-base/view"
                component={KnowledgeBaseView}
              />
              <PrivateRoute
                exact
                path="/knowledge-base/create"
                component={KnowledgeBaseCreate}
              />
              <PrivateRoute
                exact
                path="/knowledge-base/update/:id"
                component={KnowledgeBaseUpdate}
              />
              <PrivateRoute
                exact
                path="/knowledge-base/website"
                component={KnowledgeBaseWebsite}
              />

              <PrivateRoute
                exact
                path="/knowledge-base"
                component={KnowledgeBaseList}
              />

              <PrivateRoute
                exact
                path="/gallery/create"
                component={GalleryCreate}
              />
              <PrivateRoute
                exact
                path="/gallery/update/:id"
                component={GalleryUpdate}
              />
              <PrivateRoute
                exact
                path="/gallery/view/:id"
                component={GalleryView}
              />
              <PrivateRoute exact path="/gallery" component={GalleryList} />

              <PrivateRoute
                exact
                path="/manage-isp-provider/create"
                component={IspProviderCreate}
              />
              <PrivateRoute
                exact
                path="/manage-isp-provider/update/:id"
                component={IspProviderUpdate}
              />
              <PrivateRoute
                exact
                path="/manage-isp-provider"
                component={IspProviderList}
              />

              <PrivateRoute exact path="/isp" component={IspList} />
              <PrivateRoute exact path="/isp/create" component={IspCreate} />
              <PrivateRoute exact path="/isp/view/:id" component={IspView} />
              <PrivateRoute
                exact
                path="/isp/update/:id"
                component={IspUpdate}
              />
              <PrivateRoute
                exact
                path="/utility-providers"
                component={UtilityProvidersList}
              />

              <PrivateRoute
                exact
                path="/utility-providers/create"
                component={UtilityProvidersCreate}
              />
              <PrivateRoute
                exact
                path="/utility-providers/view/:id"
                component={UtilityProvidersView}
              />
              <PrivateRoute
                exact
                path="/utility-providers/update/:id"
                component={UtilityProvidersUpdate}
              />

              <PrivateRoute
                exact
                path="/service-providers"
                component={ServiceProvidersList}
              />

              <PrivateRoute
                exact
                path="/service-providers/create"
                component={ServiceProvidersCreate}
              />
              <PrivateRoute
                exact
                path="/service-providers/view/:id"
                component={ServiceProvidersView}
              />
              <PrivateRoute
                exact
                path="/service-providers/update/:id"
                component={ServiceProvidersUpdate}
              />

              <PrivateRoute path="/clock" component={Clock} />

              <PrivateRoute path="/access-control" component={AccessControl} />

              <PrivateRoute path="/admincontrol" component={AdminControl} />

              <PrivateRoute path="/expect-scripts" component={ExpectScripts} />
              <PrivateRoute path="/raddb-reports" component={RaddbReports} />

              <PrivateRoute path="/logout" component={Logout} />
              <PrivateRoute exact path="/" component={Dashboard} />

              {dynamicRoutes.map((dynamicRoute) => [
                // Main Menu
                dynamicRoute.url && dynamicRoutes.component ? (
                  <PrivateRoute
                    exact
                    path={dynamicRoute.url}
                    component={pageComponents[dynamicRoute.key]}
                  />
                ) : null,
                // Sub Menu
                dynamicRoute.items.map((subMenuItem) =>
                  subMenuItem.component ? (
                    <PrivateRoute
                      exact
                      path={subMenuItem.url}
                      component={pageComponents[subMenuItem.key] || NotFound}
                    />
                  ) : null,
                ),
              ])}
              {/*
            <Route component={NotFound} />
            */}
            </Switch>
          </Fade>
        </Suspense>
      </ControlPanelLayout>
    );
  };

  return (
    <Router>
      <Switch>
        <Route path="/login" component={PublicPages} />
        <Route path="/" component={PrivatePages} />
      </Switch>
    </Router>
  );
}

const NotFound = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
    />
  );
};

export default App;
