import { axiosBase } from "utils";

export function queryKnowledgeBaseBotService(query) {
    const axios = axiosBase.create();
    return axios({
        method: "POST",
        url: "/knowledgebot",
        data: {
            query,
        },
    });
}