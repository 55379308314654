import React, { useEffect, useState } from 'react';
import { MyBreadcrumb, accessControls } from 'utils';
import { Row, Col, Card } from 'antd';
import { useHistory } from 'react-router-dom';

import {
  CodeSandboxOutlined,
  GoldOutlined,
  HddOutlined,
  DashboardOutlined,
  TeamOutlined,
  ReadOutlined,
  DeploymentUnitOutlined,
  WifiOutlined,
  BankOutlined,
  MedicineBoxOutlined,
  ApiOutlined,
  DesktopOutlined,
  SolutionOutlined,
  PhoneOutlined,
} from '@ant-design/icons';

const { Meta } = Card;

const mdSize = 4;

function Dashboard() {
  const [permissions, setPermissions] = useState([]);

  const history = useHistory();

  useEffect(() => {
    loadPermissions();
  }, []);

  const loadPermissions = () => {
    //********START  ACCESS CONTROL******** */
    accessControls.fetch().then((permissions) => {
      permissions['Workspace' + '.' + 'read:any'] = true;
      console.log(permissions);
      setPermissions(permissions);
    });
    //********END ACCESS CONTROL******** */
  };

  if (process.env.REACT_APP_THEME === 'NETWORK_COMMANDER') {
    return (
      <div>
        <MyBreadcrumb items={[{ label: 'Home', path: '' }]} />
        <Row gutter={[12, 12]} type="flex">
          {accessControls.can(
            permissions,
            'NetworkingToolBranchCustomer.read:any',
          ) && (
            <Col md={8} xs={24}>
              <Card
                hoverable
                style={{ width: '100%', paddingTop: 30, textAlign: 'center' }}
                cover={<BankOutlined style={{ fontSize: '64px' }} />}
                onClick={() => history.push('/branchcustomer')}
              >
                <center>
                  <Meta
                    title="Branch Clients"
                    description="Branch Clients"
                    style={{
                      justifyContent: 'center',
                    }}
                  />
                </center>
              </Card>
            </Col>
          )}
          {accessControls.can(
            permissions,
            'NetworkingToolWifiUsers.read:any',
          ) && (
            <Col md={8} xs={24}>
              <Card
                hoverable
                style={{ width: '100%', paddingTop: 30, textAlign: 'center' }}
                cover={<WifiOutlined style={{ fontSize: '64px' }} />}
                onClick={() => history.push('/wifiusers')}
              >
                <Meta
                  title="Wireless Users"
                  description="Wireless Users"
                  style={{
                    justifyContent: 'center',
                  }}
                />
              </Card>
            </Col>
          )}
          {accessControls.can(
            permissions,
            'NetworkingToolOfficeRegistry.read:any',
          ) && (
            <Col md={8} xs={24}>
              <Card
                hoverable
                style={{ width: '100%', paddingTop: 30, textAlign: 'center' }}
                cover={<ApiOutlined style={{ fontSize: '64px' }} />}
                onClick={() => history.push('/officeregistry')}
              >
                <Meta
                  title="Office Assignments"
                  description="Office Assignments"
                  style={{
                    justifyContent: 'center',
                  }}
                />
              </Card>
            </Col>
          )}
          {accessControls.can(
            permissions,
            'NetworkingToolOfficeManagement.read:any',
          ) && (
            <Col md={8} xs={24}>
              <Card
                hoverable
                style={{ width: '100%', paddingTop: 30, textAlign: 'center' }}
                cover={<MedicineBoxOutlined style={{ fontSize: '64px' }} />}
                onClick={() => history.push('/officenumber')}
              >
                <Meta
                  title="Office Numbers"
                  description="Office Numbers"
                  style={{
                    justifyContent: 'center',
                  }}
                />
              </Card>
            </Col>
          )}
          {accessControls.can(permissions, 'Isp.read:any') && (
            <Col md={8} xs={24}>
              <Card
                hoverable
                style={{ width: '100%', paddingTop: 30, textAlign: 'center' }}
                cover={<DeploymentUnitOutlined style={{ fontSize: '64px' }} />}
                onClick={() => history.push('/isp')}
              >
                <Meta
                  title="ISP Management"
                  description="ISP Management"
                  style={{
                    justifyContent: 'center',
                  }}
                />
              </Card>
            </Col>
          )}
        </Row>
      </div>
    );
  } else {
    return (
      <div>
        <MyBreadcrumb items={[{ label: 'Home', path: '' }]} />
        <Row gutter={[16, 16]} type="flex">
          <Col md={24} xs={24}>
            <Row gutter={[16, 16]} type="flex">
              {accessControls.can(permissions, 'Client.read:any') && (
                <Col md={mdSize} xs={24}>
                  <Card
                    hoverable
                    style={{
                      width: '100%',
                      paddingTop: 30,
                      textAlign: 'center',
                    }}
                    cover={<HddOutlined style={{ fontSize: '64px' }} />}
                    onClick={() => history.push('/clients')}
                  >
                    <Meta title="Resellers" description="Manage Resellers" />
                  </Card>
                </Col>
              )}
              {accessControls.can(permissions, 'AccessControl.read:any') && (
                <Col md={mdSize} xs={24}>
                  <Card
                    hoverable
                    style={{
                      width: '100%',
                      paddingTop: 30,
                      textAlign: 'center',
                    }}
                    cover={<DashboardOutlined style={{ fontSize: '64px' }} />}
                    onClick={() => history.push('/access-control')}
                  >
                    <Meta
                      title="Access Control"
                      description="Manage Permissions"
                    />
                  </Card>
                </Col>
              )}
              {accessControls.can(permissions, 'Company.read:any') && (
                <Col md={mdSize} xs={24}>
                  <Card
                    hoverable
                    style={{
                      width: '100%',
                      paddingTop: 30,
                      textAlign: 'center',
                    }}
                    cover={<CodeSandboxOutlined style={{ fontSize: '64px' }} />}
                    onClick={() => history.push('/companies')}
                  >
                    <Meta
                      title="Companies"
                      description="Manage Companies Data"
                    />
                  </Card>
                </Col>
              )}
              {accessControls.can(permissions, 'Branch.read:any') && (
                <Col md={mdSize} xs={24}>
                  <Card
                    hoverable
                    style={{
                      width: '100%',
                      paddingTop: 30,
                      textAlign: 'center',
                    }}
                    cover={<GoldOutlined style={{ fontSize: '64px' }} />}
                    onClick={() => history.push('/branches')}
                  >
                    <Meta title="Branches" description="Manage Branches Data" />
                  </Card>
                </Col>
              )}
              {accessControls.can(
                permissions,
                'WorkspaceManagement.read:any',
              ) && (
                <Col md={mdSize} xs={24}>
                  <Card
                    hoverable
                    style={{
                      width: '100%',
                      paddingTop: 30,
                      textAlign: 'center',
                    }}
                    cover={<DesktopOutlined style={{ fontSize: '64px' }} />}
                    onClick={() => history.push('/workspace')}
                  >
                    <Meta
                      title="Workspace"
                      description="Manage Workspace Data"
                    />
                  </Card>
                </Col>
              )}
              {accessControls.can(
                permissions,
                'WorkspaceManagement.read:any',
              ) && (
                <Col md={mdSize} xs={24}>
                  <Card
                    hoverable
                    style={{
                      width: '100%',
                      paddingTop: 30,
                      textAlign: 'center',
                    }}
                    cover={<SolutionOutlined style={{ fontSize: '64px' }} />}
                    onClick={() => history.push('/sales')}
                  >
                    <Meta
                      title="Sales Person"
                      description="Manage Sales Agent"
                    />
                  </Card>
                </Col>
              )}
              {accessControls.can(permissions, 'User.read:any') && (
                <Col md={mdSize} xs={24}>
                  <Card
                    hoverable
                    style={{
                      width: '100%',
                      paddingTop: 30,
                      textAlign: 'center',
                    }}
                    cover={<TeamOutlined style={{ fontSize: '64px' }} />}
                    onClick={() => history.push('/users')}
                  >
                    <Meta title="Users" description="Manage User Accounts" />
                  </Card>
                </Col>
              )}
              {(accessControls.can(permissions, 'Isp.read:any') ||
                accessControls.can(permissions, 'Isp.read:any')) && (
                <Col md={mdSize} xs={24}>
                  <Card
                    hoverable
                    style={{
                      width: '100%',
                      paddingTop: 30,
                      textAlign: 'center',
                    }}
                    cover={<WifiOutlined style={{ fontSize: '64px' }} />}
                    onClick={() => history.push('/isp')}
                  >
                    <Meta
                      title="ISP Management"
                      description="Manage ISP records"
                    />
                  </Card>
                </Col>
              )}
              {(accessControls.can(permissions, 'KBCategory.read:any') ||
                accessControls.can(permissions, 'KBPost.read:any')) && (
                <Col md={mdSize} xs={24}>
                  <Card
                    hoverable
                    style={{
                      width: '100%',
                      paddingTop: 30,
                      textAlign: 'center',
                    }}
                    cover={<ReadOutlined style={{ fontSize: '64px' }} />}
                    onClick={() => history.push('/knowledge-base/view')}
                  >
                    <Meta title="KB" description="Knowldge Management" />
                  </Card>
                </Col>
              )}

              {accessControls.can(permissions, 'Client.read:any') && (
                <Col md={mdSize} xs={24}>
                  <Card
                    hoverable
                    style={{
                      width: '100%',
                      paddingTop: 30,
                      textAlign: 'center',
                    }}
                    cover={<PhoneOutlined style={{ fontSize: '64px' }} />}
                    onClick={() => history.push('/call-report')}
                  >
                    <Meta
                      title="Call Reports"
                      description="Manage Call Report"
                    />
                  </Card>
                </Col>
              )}

              {accessControls.can(permissions, [
                'NetworkingToolCountries.read:any',
                'NetworkingToolStates.read:any',
                'NetworkingToolBandwidth.read:any',
                'NetworkingToolEquipment.read:any',
                'NetworkingToolVendor.read:any',
                'NetworkingToolModel.read:any',
                'NetworkingToolQosOptions.read:any',
                'NetworkingToolBranchNetwork.read:any',
                'NetworkingToolBranchCustomer.read:any',
                'NetworkingToolVlanRegistry.read:any',
                'NetworkingToolDeviceRegistry.read:any',
                'NetworkingToolIpRegistry.read:any',
                'NetworkingToolPortRegistry.read:any',
                'NetworkingToolIpPortRegistry.read:any',
                'NetworkingToolOfficeRegistry.read:any',
                'NetworkingToolWifiGroups.read:any',
                'NetworkingToolWifiUsers.read:any',
                'NetworkingToolEquipmentOwnership.read:any',
                'NetworkingToolClientFirewall.read:any',
                'NetworkingToolFirewallPorts.read:any',
              ]) && (
                <Col md={mdSize} xs={24}>
                  <Card
                    hoverable
                    style={{
                      width: '100%',
                      paddingTop: 30,
                      textAlign: 'center',
                    }}
                    cover={
                      <DeploymentUnitOutlined style={{ fontSize: '64px' }} />
                    }
                    onClick={() => history.push('/network-dashboard')}
                  >
                    <Meta
                      title="Network Management"
                      description="Network Management"
                    />
                  </Card>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Dashboard;
